<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header
          style="border-bottom: 1px solid rgb(235, 237, 240)"
          title="用户信息"
        >
        </a-page-header>
      </div>
      
      <div ref="form">
        <a-form
          :form="form"
          layout="inline"
          @submit="handleSubmit"
          style="padding: 15px 0"
          ref="form"
        >
        <a-form-item label="抖音昵称">
                      <a-input v-decorator="[
                          'nickname_search',
                          { rules: [{ required: false }] },
                        ]" />
                    </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getExport(0)">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :columns="columns"
        :data-source="posterList"
        :pagination="false"
        :scroll="{ x: 1000, y: table_h }"
        :row-key="(record) => record.id"
        size="middle"
        ref="tableList"
      >
       
      </a-table>
      <div ref="pagination">
        <a-pagination
          v-if="pageshow"
          :default-current="currentPage"
          :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions"
          show-size-changer
          show-quick-jumper
          :total="total_num"
          :show-total="(total) => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      color: "",
      color1: "",
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      columns: [
        {
          title: "抖音UID",
          key: "account_number",
          dataIndex: "account_number",
          align: "center",
        },
        {
          title: "抖音昵称",
          key: "nickname",
          dataIndex: "nickname",
          align: "center",
        },
        {
          title: "手机号",
          key: "mobile",
          dataIndex: "mobile",
          align: "center",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          align: "center",
        },
      ],
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      posterList: [],
      canUpload: "",
      gid: 50,
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.header.offsetHeight
        -this.$refs.pagination.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/DouyinJingdongNew20221011/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var posterList = res.data.data;
            that.changeTableH();
            that.posterList = posterList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //上传图片
    changeImg(info) {
      var that = this;
      const formData = new FormData();
      formData.append("file", info.file);
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.axios.post("/admin/upload/image", formData).then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var img = res.data.data.path_url;
          that.addform.setFieldsValue({
            img: img,
          });
          that.fileList = [
            {
              uid: info.file.uid,
              name: info.file.name,
              status: "done",
              url: "" + img,
              thumbUrl: "" + img,
            },
          ];
        }
      });
    },
    //删除图片
    removeImg() {
      this.fileList = [];
    },
    //上传前校验
    handleBeforeUpload(file) {
      if (file.type.indexOf("image") < 0) {
        this.$message.error("仅支持上传图片");
        this.canUpload = false;
        return false;
      } else {
        this.canUpload = true;
        return true;
      }
    },
    getchangeImg(info) {
      if (!this.canUpload) {
        info.fileList = [];
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn = false;
      } else {
        this.uploadBtn = true;
        if (info.fileList.length == 0) {
          this.addform.setFieldsValue({
            img: "",
          });
        }
      }
    },

    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}
.ant-table-wrapper {
  flex: 1;
}
.ant-modal-body form {
  overflow: hidden;
}
.ant-input-number {
  width: 100%;
}
.m-colorPicker {
  position: absolute;
  height: 39px;
  right: 0;
  width: 100%;
}
/deep/.m-colorPicker .colorBtn {
  margin-top: 8px;
  position: absolute;
  right: 9px;
  top: 5px;
}
/deep/.m-colorPicker .box {
  right: 0;
}
</style>